<template>
  <div>

    <div id="dvcard">
      <va-card>
        <va-card-title>
          <h1>FILTROS</h1>
        </va-card-title>
        <va-card-content>
          <div class="row align--center mb-1">
            <div class="flex xs12 sm3">
              <DxSelectBox id="cbAtivo" :data-source="dataserials" label="IDS" label-mode="static"
                @ValueChanged="SelecionaFiltros($event)" />
            </div>

            <div class="flex xs12 sm3" v-if="datasites">
              <DxSelectBox id="cbSite" :data-source="datasites" label="Site" label-mode="static" v-model="xsite" />
            </div>

            <div class="flex xs12 sm3">
                <DxDateBox v-model="xdatai" :value="now" date-serialization-format="yyyy-MM-ddTHH:mm:ss" :input-attr="{ 'aria-label': 'Data e Hora' }" type="datetime" label="Data inicial" label-mode="static" />
            </div>

            <div class="flex xs12 sm3">
                <DxDateBox v-model="xdataf" :value="now" date-serialization-format="yyyy-MM-ddTHH:mm:ss" :input-attr="{ 'aria-label': 'Data e Hora' }" type="datetime" label="Data final" label-mode="static" />
            </div>


          </div>
        </va-card-content>
      </va-card>
    </div>


    <div class="row row-equal" v-if="dataDados">
      <div class="flex xl12 xs12">
        <div class="row">
          <div class="flex xs12 md4">
            <va-card>
              <va-card-content>
                Maior sequencia BLUE
                <div class="mr-0 text-right">
                  <h2>{{ dataDados[0].MaxBlueSequence }}</h2>
                  {{ formatDateTime(dataDados[0].MaxBlueSequenceTime) }}
                </div>
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs12 md4">
            <va-card>
              <va-card-content>
                Maior sequencia RED
                <div class="mr-0 text-right">
                  <h4>{{ dataDados[0].MaxRedSequence }}</h4>
                  {{ formatDateTime(dataDados[0].MaxRedSequenceTime) }}
                </div>
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs12 md4">
            <va-card>
              <va-card-content>
                Maior sequencia TIE
                <div class="mr-0 text-right">
                  <h4>{{ dataDados[0].MaxTieSequence }}</h4>
                  {{ formatDateTime(dataDados[0].MaxTieSequenceTime) }}
                </div>
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs12 md4">
            <va-card>
              <va-card-content>
                Total WINS
                <div class="mr-0 text-right">
                  <h4>{{ dataDados[0].TotalWins }}</h4>
                </div>
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs12 md4">
            <va-card>
              <va-card-content>
                Total LOSS
                <div class="mr-0 text-right">
                  <h4>{{ dataDados[0].TotalLosses }}</h4>
                </div>
              </va-card-content>
            </va-card>
          </div>

          <div class="flex xs12 md4">
            <va-card>
              <va-card-content>
                Session profit
                <div class="mr-0 text-right">
                  <h4>{{ dataDados[0].SessionProfit }}</h4>
                </div>
              </va-card-content>
            </va-card>
          </div>

        </div>
      </div>
    </div>


    <div id="dvGrid">
      <dx-data-grid ref="gdListaVendas" class="dx-card wide-card" :data-source="dataVenda" :focused-row-index="0"
        :show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true"
        :context-menu-enabled="true" key-expr="id" @cell-prepared="onCellPrepared"
        @toolbar-preparing="onToolbarPreparing($event)">
        <dx-paging :page-size="50" />
        <dx-pager :show-page-size-selector="true" :show-info="true" />

        <dx-column data-field="id" caption="Id" :visible="false" />
        <dx-column data-field="site" caption="Site" />
        <dx-column data-field="datahora" caption="Data jogo" data-type="date" format="dd/MM/yyyy HH:mm:ss" />
        <dx-column data-field="valorinicial" caption="Valor inicial" />
        <dx-column data-field="valoraposta" caption="Valor aposta" />
        <dx-column data-field="lucro" caption="Ganho" />
        <dx-column data-field="lucrosessao" caption="Lucro sessão" />

        <dx-column data-field="cor" caption="Cor aposta" cell-template="statusTemplate" />
        <dx-column data-field="vencedor" caption="Vencedor" cell-template="statusTemplate" />
        <dx-column data-field="emjogo" caption="Jogou" cell-template="emjogoTemplate" />

        <template #emjogoTemplate="{ data }">
          <span v-if="data.value == 1" class="jogou-sim">SIM</span>
          <span v-else class="jogou-nao">NÃO</span>
        </template>

        <template #statusTemplate="{ data }">
          <va-chip v-if="data.value == ''" class="mb-2 mr-2" color="default">Sem aposta</va-chip>
          <va-chip v-if="data.value == 'RED'" class="mb-2 mr-2" color="danger">RED</va-chip>
          <va-chip v-if="data.value == 'BLUE'" class="mb-2 mr-2" color="primary">BLUE</va-chip>
          <va-chip v-if="data.value == 'EMPATE'" class="mb-2 mr-2" color="warning">EMPATE</va-chip>
        </template>
      </dx-data-grid>
    </div>

    <DxLoadPanel :position="{ of: '#dvListaVendas' }" v-model:visible="loadingVisible" :shading="true"
      shading-color="rgba(0,0,0,0.4)" />
  </div>
</template>

<script>
import conserv from "@/api/conserv";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxExport,
  DxButton,
} from "devextreme-vue/data-grid";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxDateBox from 'devextreme-vue/date-box';

export default {
  name: "licencas",
  mixins: [conserv],
  components: {
    DxLoadPanel,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxExport,
    DxButton,
    DxSelectBox,
    DxDateBox,
  },
  data() {
    return {
      dataserials: [],
      valorserial: '',
      loadingVisible: false,
      dataVenda: null,
      dataDados: null,
      datasites: null,
      regEdicao: {},
      xsite: '',
      xdatai: new Date().toISOString(),
      xdataf: new Date().toISOString(),
    };
  },
  methods: {
    formatDateTime(dateTimeStr) {
      const date = new Date(dateTimeStr);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
    async SelecionaFiltros(e) {
      this.valorserial = e.value;
      console.log("valorserial = ", this.valorserial);
      await this.listaSites();
    },
    verPago(e) {
      if (e.row.data.pago == "1") return false;
      else return true;
    },
    AbrePagamento(e) {
      var reg = e.row.data;
      window.open(reg.urlpag, "_blank").focus();
    },
    onCellPrepared(e) {
      if (e.column.dataField === "valorinicial" || e.column.dataField === "valoraposta" || e.column.dataField === "lucro" || e.column.dataField === "lucrosessao") {
        const tpFormatValor = {
          style: "currency",
          currency: "BRL",
          useGrouping: true,
          precision: 2,
        };
        e.column.format = tpFormatValor;
      }
    },
    async ListaRegistros() {
      this.loadingVisible = true;
      let regs = await this.connServ("api/Bacbo/ListaRegistros?xIdPc=" + this.valorserial, "post", "");
      console.log(JSON.stringify(regs));
      this.dataVenda = [...regs.dados.registro];
      this.loadingVisible = false;
      this.ListaDados();
    },
    async listaSites() {
      this.loadingVisible = true;
      let regs = await this.connServ("api/Bacbo/ListaSites?idpc=" + this.valorserial, "get", "");
      console.log(JSON.stringify(regs));
      this.datasites = [...regs.dados.registro];
      this.loadingVisible = false;
    },
    async ListaDados() {
      console.log('datas = ', this.xdatai, " - ", this.xdataf);

      this.loadingVisible = true;
      const filterJson = {
        idpc: this.valorserial,
        site: this.xsite,
        startdate: new Date(this.xdatai).toISOString(),
        enddate: new Date(this.xdataf).toISOString()
      };
      console.log('filterJson = ', JSON.stringify(filterJson));
      try {
        let regs = await this.connServ("api/Bacbo/ListaDadosBacBo", "post", filterJson);
        this.dataDados = [...regs.dados.registro];
        console.log(JSON.stringify(this.dataDados));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loadingVisible = false;
      }
    },
    async ListaSerials() {
      this.loadingVisible = true;
      let regs = await this.connServ("api/Bacbo/ListaMaquinas", "get", "");
      console.log(JSON.stringify(regs));
      this.dataserials = regs.dados.registro.map(record => record.idpc);
      console.log('this.dataserials = ', JSON.stringify(this.dataserials));
      this.loadingVisible = false;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Atualizar",
          elementAttr: {
            id: "btAtualiza",
          },
          onClick: this.ListaRegistros.bind(this),
        },
      });
    },
  },
  mounted() {
    this.ListaSerials();
  },
};
</script>

<style>
.jogou-sim {
  color: green
}

.jogou-nao {
  color: red
}

.cssDemo {
  color: chocolate;
}

.cssReal {
  color: green;
}
</style>